import {redirect, useParams} from "react-router-dom";
import {AxiosInstance} from "../services/http";
import {useEffect, useState} from "react";
import {useToast} from "../contexts/ToastContext";
import {Loading} from "../components/loading";
import {NotFound} from "./not.found";
import {ReactSVG} from "react-svg";
import {CheckCircleIcon} from "@heroicons/react/20/solid";

export const Category = (props) => {
    const {category_id} = useParams();
    const toast = useToast();
    const [fetching, setFetching] = useState(false);
    const [category, setCategory] = useState(undefined);
    const [categoryIcon, setCategoryIcon] = useState('');
    const [isUpdatingCategory, setIsUpdatingCategory] = useState(false);
    const [isDeletingCategory, setIsDeletingCategory] = useState(false);
    const [isUploadingCategoryIcon, setIsUploadingCategoryIcon] = useState(false);
    useEffect(() => {
        if (category_id){
            setFetching(true);
            getCategory(category_id);
        }else{
            setCategory({
                name: '',
                badge: '',
                order: '',
                icon: '',
                in_icons_view_box: false,
                in_animations_view_box: false,
                in_3ds_view_box: false
            })
        }
    }, [category_id]);

    const getCategory = () => {
        AxiosInstance('GET', '/api/v1/categories/' + category_id)
            .then(res => {
                if (res.status === 200){
                    const response = res.data.resulting_data;
                    setCategory(response);
                }else {
                    setCategory(null);
                    toast('error', 'Error!', 'Error fetching category!');
                }
                setFetching(false);
            })
    }

    const updateCategory = (icon) => {
        setIsUpdatingCategory(true);
        const uri = category_id ? `/api/v1/categories/${category_id}` : '/api/v1/categories';
        AxiosInstance(category_id ? 'PUT' : 'POST', uri,{},{
            name: category.name,
            badge: category.badge,
            order: category.order,
            in_icons_view_box: category.in_icons_view_box,
            in_animations_view_box: category.in_animations_view_box,
            in_3ds_view_box: category.in_3ds_view_box,
            icon: icon !== '' ? icon : undefined
        })
            .then(res => {
                if (res.status === 200){
                    const response = res.data.resulting_data;
                    if (!category_id){
                        window.location.replace(`/categories/${response.id}`);
                    }
                    setCategory(response);
                    toast('success', 'Success!', 'Category added/updated successfully!');
                }else{
                    toast('error', 'Error!', 'Error adding/updating icon category!');
                }
                setIsUpdatingCategory(false);
            });
    }

    const deleteCategory = () => {

        setIsDeletingCategory(true);

        AxiosInstance('DELETE',`/api/v1/categories/${category_id}`)
            .then(res => {
                setIsDeletingCategory(false);
                if (res.status === 200){
                    window.location.replace('/categories/new')
                    toast('success', 'Success!', 'Category deleted successfully!');
                }else{
                    toast('error', 'Error!', 'Error deleting category!');
                }
            });
    }

    const uploadCategoryIcon = (icon) => {

        setIsUploadingCategoryIcon(true);

        const formData = new FormData();

        formData.append(
            "file",
            icon,
            `category-${category_id ?? 'new-category'}-${(Math.random() + 1).toString(36).substring(7)}`
        );

        formData.append('model', 'category');

        AxiosInstance('POST',`/api/v1/upload`,{},formData)
            .then(res => {
                setIsUploadingCategoryIcon(false);
                if (res.status === 200){
                    const icon = res.data.resulting_data;
                    setCategoryIcon(icon);
                    toast('success', 'Success!', 'Category icon successfully uploaded!');
                }else{
                    toast('error', 'Error!', 'Error uploading category icon!');
                }
            });
    }

    return (
        fetching ? <Loading/> : (
            category ? (
                <div className="space-y-10 divide-y divide-gray-900/10">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-6">
                        <div className="px-4 sm:px-0  md:col-span-1">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">
                                Category Details
                            </h2>
                        </div>

                        <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-6">
                            <div className="px-4 py-6 sm:p-8">
                                <div className="grid grid-cols-6 gap-x-6 gap-y-8 sm:grid-cols-12">
                                    <div className="sm:col-span-1">
                                        <label htmlFor="title"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Icon {category_id ? `(id : ${category.id})` : ''}
                                        </label>
                                        <div className="mt-2">
                                            <ReactSVG src={category.icon}/>
                                        </div>
                                        <input
                                            type="text"
                                            hidden={true}
                                            name="icon"
                                            id="icon"
                                            value={categoryIcon}
                                            onChange={(e) => setCategoryIcon(e.target.value)}
                                        />

                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="title"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                value={category.name}
                                                onChange={(e) => setCategory({...category, name: e.target.value})}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="first-name"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Badge
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                name="badge"
                                                id="badge"
                                                value={category.badge}
                                                onChange={(e) => setCategory({...category, badge: e.target.value})}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <label htmlFor="first-name"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Order
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="number"
                                                name="order"
                                                id="order"
                                                value={category.order}
                                                onChange={(e) => setCategory({...category, order: e.target.value})}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <label htmlFor="first-name"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            In Icons Box View
                                        </label>
                                        <div className="mt-2 w-5 h-5 items-center">
                                            <input
                                                    checked={category.in_icons_view_box}
                                                    type="checkbox"
                                                    name="in_icons_view_box"
                                                    id="in_icons_view_box"
                                                    onChange={(e) => setCategory({...category, in_icons_view_box: !category.in_icons_view_box})}
                                                    className="block w-full h-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <label htmlFor="first-name"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            In Animations Box View
                                        </label>
                                        <div className="mt-2 w-5 h-5 items-center">
                                            <input
                                                    checked={category.in_animations_view_box}
                                                    type="checkbox"
                                                    name="in_animations_view_box"
                                                    id="in_animations_view_box"
                                                    onChange={(e) => setCategory({...category, in_animations_view_box: !category.in_animations_view_box})}
                                                    className="block w-full h-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-1">
                                        <label htmlFor="first-name"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            In 3Ds Box View
                                        </label>
                                        <div className="mt-2 w-5 h-5 items-center">
                                            <input
                                                    checked={category.in_3ds_view_box}
                                                    type="checkbox"
                                                    name="in_3ds_view_box"
                                                    id="in_3ds_view_box"
                                                    onChange={(e) => setCategory({...category, in_3ds_view_box: !category.in_3ds_view_box})}
                                                    className="block w-full h-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-4">
                                        <label htmlFor="first-name"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Actions
                                        </label>
                                        <div className="mt-2">
                                            <span className="mr-2">
                                                <label
                                                    htmlFor='icon-upload'
                                                    className="cursor-pointer rounded-md bg-indigo-600 px-3 py-2.5 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                {isUploadingCategoryIcon ? 'Uploading Icon' : (category_id ? 'Change Icon' : 'Add Icon')}
                                                </label>
                                                <input id="icon-upload" name="file-upload" type="file"
                                                       className="cursor-pointer sr-only" onChange={(e) => {
                                                    uploadCategoryIcon(e.target.files[0]);
                                                }}/>
                                            </span>
                                            {
                                                category_id && <button
                                                    disabled={isDeletingCategory}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        deleteCategory();
                                                    }}
                                                    type="submit"
                                                    className="mr-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                                >
                                                    {isDeletingCategory ? 'Please Wait' : 'Delete'}
                                                </button>
                                            }
                                            <button
                                                disabled={isUpdatingCategory}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    updateCategory(categoryIcon);
                                                }}
                                                type="submit"
                                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                {isUpdatingCategory ? 'Please Wait' : (category_id ? 'Update' : 'Add Category')}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-6">
                                        {
                                            categoryIcon &&
                                            <div className="rounded bg-green-50 p-4">
                                                <div className="flex">
                                                    <div className="flex-shrink-0">
                                                        <CheckCircleIcon className="h-5 w-5 text-green-400"
                                                                         aria-hidden="true"/>
                                                    </div>
                                                    <div className="ml-3">
                                                        <h3 className="text-sm font-medium text-green-800">Icon Uploaded!</h3>
                                                        <div className="mt-2 text-sm text-green-700">
                                                            <p>Category icon uploaded. Just click on Update/Add Category button to complete process!</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            ) : <NotFound/>
        )
    )
}
